<template>
  <div class="container top-conteudo">
    <div class="row justify-content-center" v-if="getPaginasLoading">
      <span class="spinner-border" role="status" aria-hidden="true"
        >CARREGANDO...</span
      >
    </div>
    <div class="row" v-if="!getPaginasLoading">
      <conteudo :rota="rota" :paginas="getPaginas"></conteudo>
    </div>
  </div>
</template>

<script>
import Conteudo from '@/views/paginaEstatica/conteudo.vue';
import { mapGetters, mapActions } from 'vuex';
export default {
  components: {
    Conteudo,
  },
  data() {
    return {
      html: '',
      rota: '',
      titulo: '',
    };
  },
  computed: {
    ...mapGetters(['getPaginasLoading', 'getPaginas']),
  },
  methods: {
    ...mapActions(['setPaginas']),
  },
  async created() {
    if (this.$router.history.current.params.pagina) {
      this.rota = this.$router.history.current.params.pagina;
    }
  },
};
</script>

<style scoped>
.top-conteudo {
  padding: 150px 20px 0;
}

img {
  width: 100%;
}
</style>
